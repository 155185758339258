<script>
import { required } from "vuelidate/lib/validators";

import {
  authMethods,
  authFackMethods,
  notificationMethods
} from "@/state/helpers";

export default {
  data() {
    return {
      tel: "",
      password: "",
      submitted: false
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    }
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  validations: {
    tel: { required },
    password: { required }
  },
  methods: {
    ...authMethods,
    ...authFackMethods,
    ...notificationMethods,
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        /*if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          this.tryingToLogIn = true;
          // Reset the authError if it existed.
          this.authError = null;
          return (
            this.logIn({
              tel: this.tel,
              password: this.password
            })
              // eslint-disable-next-line no-unused-vars
              .then(token => {
                this.tryingToLogIn = false;
                this.isAuthError = false;
                // Redirect to the originally requested page, or to the home page
                this.$router.push(
                  this.$route.query.redirectFrom || { name: "home" }
                );
              })
              .catch(error => {
                this.tryingToLogIn = false;
                this.authError = error ? error : "";
                this.isAuthError = true;
              })
          );
        } else {*/
          const { tel, password } = this;
          if (tel && password) {
            this.login({ tel, password });
          }
        //}
      }
    }
  }
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-5">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-12">
                    <div>
                      <div class="text-center">
                        <div class="mt-5">
                          <a href="/" class="logo">
                            <img src="@/assets/images/rmolog.png" height="30" alt="logo" />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-0">Bon retour !</h4>
                       <!--  <p class="text-muted">Connectez-vous.</p> -->
                      </div>

                      <b-alert
                        variant="danger"
                        class="mt-3"
                        v-if="notification.message"
                        show
                        dismissible
                      >{{notification.message}}</b-alert>

                      <div class="p-2 mt-5">
                        <form class="form-horizontal" @submit.prevent="tryToLogIn" @keyup.enter="tryToLogIn" target="0">
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-phone-line auti-custom-input-icon text-green"></i>
                            <label for="tel">Numéro de télephone</label>
                            <input
                              type="tel"
                              v-model="tel"
                              class="form-control"
                              id="tel"
                              placeholder="Entrer le numéro de télephone"
                              :class="{ 'is-invalid': submitted && $v.tel.$error }"
                            />
                            <div v-if="submitted && $v.tel.$error" class="invalid-feedback">
                              <span v-if="!$v.tel.required">Numéro de télephone requis.</span>
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-lock-2-line auti-custom-input-icon"></i>
                            <label for="userpassword">Mot de Passe</label>
                            <input
                              v-model="password"
                              type="password"
                              class="form-control"
                              id="userpassword"
                              placeholder="Entrer le mot de passe"
                              :class="{ 'is-invalid': submitted && $v.password.$error }"
                            />
                            <div
                              v-if="submitted && !$v.password.required"
                              class="invalid-feedback"
                            >Mot de Passe requis.</div>
                          </div>

                          <!--<div class="custom-control custom-checkbox text-center">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="customControlInline"
                            />
                            <label
                              class="custom-control-label"
                              for="customControlInline"
                            >Remember me</label>
                          </div>-->

                          <div class="mt-4 text-center">
                            <button
                              class="btn bg-green w-md  waves-light"
                              type="submit"
                            >Connexion</button>
                          </div>

                          <!--<div class="mt-4 text-center">
                            <router-link tag="a" to="/forgot-password" class="text-muted">
                              <i class="mdi mdi-lock mr-1"></i> Forgot your password?
                            </router-link>
                          </div>-->
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <!--<p>
                          Don't have an account ?
                          <router-link
                            tag="a"
                            to="/register"
                            class="font-weight-medium text-primary"
                          >Register</router-link>
                        </p>-->
                        <p>
                          © 2023 RMobility
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-7">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style>
.bg-green{
  background  :#3AAA35;
  color: #fff;
}

.text-green{
  color:#3AAA35;
}

.auth-form-group-custom .auti-custom-input-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 19px;
    font-size: 24px;
    color: #3AAA35!important;
}
</style>